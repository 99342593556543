import { convertToPathName } from '../utils/url';
import {
    ACTIVE_STATES,
    ALL_PUZZLEHUNT_STATES,
    ARCHIVED_YEARS,
    PUZZLEHUNT_NAME,
    PuzzlehuntState,
    REGISTRATION_STATES,
} from './puzzlehunt_details';

export const PUZZLE_PAGE_PREPEND_URL = '/puzzle';

// All page names/titles.
export const PAGE_NAMES = {
    ADMIN: 'Admin Portal', // URL endpoint only. This will not be accessible through the website and should be password-protected.
    ABOUT_US: 'About Us',
    ACCOUNT: 'Account',
    ANNOUNCEMENTS: 'Announcements',
    ARCHIVE: 'Archive',
    HIGHLIGHTS: 'Highlights',
    HINTS: 'Hints',
    FORGOT_PASSWORD: 'Reset Password',
    PUZZLES: 'Puzzles',
    RULES_AND_INFO: 'Rules + Info',
    LOGIN: 'Login',
    REGISTER: 'Register',
    TEAMS: 'Teams',
};
export const ACTIVE_PAGES: {
    [
        pageName: (typeof PAGE_NAMES)[keyof typeof PAGE_NAMES]
    ]: (typeof PuzzlehuntState)[keyof typeof PuzzlehuntState][];
} = {
    // Pages that are always accessible.
    [PAGE_NAMES.RULES_AND_INFO]: ALL_PUZZLEHUNT_STATES,
    [PAGE_NAMES.ANNOUNCEMENTS]: ALL_PUZZLEHUNT_STATES,
    [PAGE_NAMES.ARCHIVE]: ALL_PUZZLEHUNT_STATES,
    [PAGE_NAMES.HIGHLIGHTS]: ALL_PUZZLEHUNT_STATES,
    [PAGE_NAMES.ABOUT_US]: ALL_PUZZLEHUNT_STATES,
    // Pages that become accessible once registration opens.
    [PAGE_NAMES.ACCOUNT]: REGISTRATION_STATES,
    [PAGE_NAMES.FORGOT_PASSWORD]: REGISTRATION_STATES,
    [PAGE_NAMES.LOGIN]: REGISTRATION_STATES,
    [PAGE_NAMES.REGISTER]: REGISTRATION_STATES,
    // Pages that are only accessible during the puzzlehunt.
    [PAGE_NAMES.TEAMS]: ACTIVE_STATES,
    [PAGE_NAMES.PUZZLES]: ACTIVE_STATES,
    [PAGE_NAMES.HINTS]: ACTIVE_STATES,
};
export const ARCHIVED_HUNT_NAMES = ARCHIVED_YEARS.map((year) => `${PUZZLEHUNT_NAME} ${year}`);

export const ARCHIVED_HUNT_URL_TO_PAGE_NAME: { [url: string]: string } = Object.fromEntries(
    ARCHIVED_HUNT_NAMES.map((huntName) => {
        return [convertToPathName(huntName), huntName];
    }),
);

// Mappings to help associate page to URLs for routing purposes.
export const PAGE_TO_URL: { [name: string]: string } = {
    ...Object.fromEntries(
        Object.values(PAGE_NAMES).map((pageName) => [pageName, convertToPathName(pageName)]),
    ),
    ...Object.fromEntries(
        ARCHIVED_HUNT_NAMES.map((huntName) => [huntName, convertToPathName(huntName)]),
    ),
};

// Map of archived hunt options to their respective URLs.
export const ARCHIVED_HUNT_MENU_OPTIONS: { [name: string]: { link: string } } = {
    [PAGE_NAMES.HIGHLIGHTS]: { link: PAGE_TO_URL[PAGE_NAMES.HIGHLIGHTS] },
    ...Object.fromEntries(
        ARCHIVED_HUNT_NAMES.map((huntName) => {
            return [huntName, { link: PAGE_TO_URL[huntName] }];
        }),
    ),
};

export const isPathUnderPageOption = (path: string, pageName: string) => {
    if (
        path === PAGE_TO_URL[PAGE_NAMES.FORGOT_PASSWORD] ||
        path === PAGE_TO_URL[PAGE_NAMES.REGISTER]
    ) {
        // Special case for all the account login/registration pages because
        // we want them to all show under the same button.
        return pageName === PAGE_NAMES.LOGIN;
    }
    if (path === PAGE_TO_URL[PAGE_NAMES.HIGHLIGHTS] || path in ARCHIVED_HUNT_URL_TO_PAGE_NAME) {
        // Special case for the highlights and archived hunts because we want them
        // to all show up under the "Archive" button.
        return pageName === PAGE_NAMES.ARCHIVE;
    }
    return PAGE_TO_URL[pageName] === path;
};
