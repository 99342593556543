/** @jsxImportSource @emotion/react */
import ConditionalLink from '../../components/ConditionalLink/ConditionalLink';
import { CSS_IGNORE_TOP_CONTENT_PADDING } from '../../utils/content_page_css';
import { HELP_EMAIL } from '../../data/puzzlehunt_details';
import { ABOUT_US_SECTION } from './about_us_page_defs';
import AboutHolidayHoopla from './AboutHolidayHoopla';
import MeetTheTeamContent from './MeetTheTeamContent';

function ContactUs() {
    return (
        <div css={ABOUT_US_SECTION}>
            <h2>Have questions or feedback?</h2>
            <p>
                Contact us at{' '}
                <ConditionalLink link={`mailto:${HELP_EMAIL}`} showUnderline>
                    {HELP_EMAIL}
                </ConditionalLink>{' '}
                with any questions, comments or feedback!
            </p>
        </div>
    );
}

function AboutUsContent() {
    return (
        <div css={CSS_IGNORE_TOP_CONTENT_PADDING}>
            <AboutHolidayHoopla />
            <MeetTheTeamContent />
            <ContactUs />
        </div>
    );
}

export default AboutUsContent;
