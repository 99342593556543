/** @jsxImportSource @emotion/react */
import { SPECIAL_CHARACTERS } from '../../utils/text_formatting';
import { ABOUT_US_SECTION } from './about_us_page_defs';
import ConditionalLink from '../../components/ConditionalLink/ConditionalLink';

function AboutHolidayHoopla() {
    return (
        <div css={ABOUT_US_SECTION}>
            <h2>About Holiday Hoopla</h2>
            <p>
                In the midst of the pandemic, while human connection was scarce and holiday spirit
                was at an understandable low, we searched for something to bring us all together:
                Holiday Hoopla!
            </p>
            <p>
                After participating in puzzle hunts such as the{' '}
                <ConditionalLink link="https://www.integirls.org/puzzle" showUnderline>
                    Integirls Puzzlehunt
                </ConditionalLink>{' '}
                from 2020, where we placed 3rd overall,{' '}
                <ConditionalLink link="https://darocaro.github.io/" showUnderline>
                    DaroCaro Puzzles
                </ConditionalLink>
                , and{' '}
                <ConditionalLink link="https://curiouscookoff.com/" showUnderline>
                    Colby's Curious Cookoff
                </ConditionalLink>
                , we were inspired to write our own puzzles. Not many recurring puzzle hunts revolve
                around holidays, and few puzzle hunts were running during the holiday season, while
                we were all stuck inside and bored. As avid holiday lovers, we decided to remedy
                this issue with fun, festive, themed puzzles open to teams of all ages and from all
                places.
            </p>
            <p>
                Even in the most isolated times, we hoped Holiday Hoopla would remind us all of some
                good in the world. We're proud to have come together as an international community,
                working together and solving puzzles. When we first started, we thought it
                unfathomable that we could've had over 100 teams participating each year{' '}
                {SPECIAL_CHARACTERS.EM_DASH} we never thought it would go this far.
            </p>
            <p>
                The spirit of building community and bringing people together still remains as we
                approach our fifth year together. An escape from the daily boredom of school and
                work, Holiday Hoopla remains an important part of our lives, and we hope you've
                enjoyed these past few years as much as we have!
            </p>
            <p>
                <b>
                    If you're wondering about what a puzzle hunt actually is, we recommend checking
                    out{' '}
                    <ConditionalLink link="https://blog.vero.site/post/puzzlehunts" showUnderline>
                        betaveros' Introduction to Puzzlehunts
                    </ConditionalLink>{' '}
                    and{' '}
                    <ConditionalLink link="https://www.yukihunt.club/" showUnderline>
                        YukiHunt
                    </ConditionalLink>{' '}
                    for more info.
                </b>
            </p>
        </div>
    );
}

export default AboutHolidayHoopla;
